import i18n from '/common/plugins/vue-i18n.js' 
let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang


const GPTModelList =  [
    {name:"GPT 3.5",id:"gpt35turbo"},
    {name:"GPT 4",id:"gpt4turbo"},
    {name:"GPT 4o",id:"gpt4o"},
    {name:"GPT 4o mini",id:"gpt4o-mini"},
];

export default GPTModelList;